"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _HiddenJs = _interopRequireDefault(require("./HiddenJs"));

var _HiddenCss = _interopRequireDefault(require("./HiddenCss"));

/**
 * Responsively hides children based on the selected implementation.
 */
function Hidden(props) {
  var implementation = props.implementation,
      other = (0, _objectWithoutProperties2.default)(props, ["implementation"]);

  if (implementation === 'js') {
    return _react.default.createElement(_HiddenJs.default, other);
  }

  return _react.default.createElement(_HiddenCss.default, other);
}

process.env.NODE_ENV !== "production" ? Hidden.propTypes = {
  /**
   * The content of the component.
   */
  children: _propTypes.default.node,

  /**
   * @ignore
   */
  className: _propTypes.default.string,

  /**
   * Specify which implementation to use.  'js' is the default, 'css' works better for
   * server-side rendering.
   */
  implementation: _propTypes.default.oneOf(['js', 'css']),

  /**
   * You can use this property when choosing the `js` implementation with server-side rendering.
   *
   * As `window.innerWidth` is unavailable on the server,
   * we default to rendering an empty component during the first mount.
   * You might want to use an heuristic to approximate
   * the screen width of the client browser screen width.
   *
   * For instance, you could be using the user-agent or the client-hints.
   * https://caniuse.com/#search=client%20hint
   */
  initialWidth: _propTypes.default.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

  /**
   * If true, screens this size and down will be hidden.
   */
  lgDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  lgUp: _propTypes.default.bool,

  /**
   * If true, screens this size and down will be hidden.
   */
  mdDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  mdUp: _propTypes.default.bool,

  /**
   * Hide the given breakpoint(s).
   */
  only: _propTypes.default.oneOfType([_propTypes.default.oneOf(['xs', 'sm', 'md', 'lg', 'xl']), _propTypes.default.arrayOf(_propTypes.default.oneOf(['xs', 'sm', 'md', 'lg', 'xl']))]),

  /**
   * If true, screens this size and down will be hidden.
   */
  smDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  smUp: _propTypes.default.bool,

  /**
   * If true, screens this size and down will be hidden.
   */
  xlDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  xlUp: _propTypes.default.bool,

  /**
   * If true, screens this size and down will be hidden.
   */
  xsDown: _propTypes.default.bool,

  /**
   * If true, screens this size and up will be hidden.
   */
  xsUp: _propTypes.default.bool
} : void 0;
Hidden.defaultProps = {
  implementation: 'js',
  lgDown: false,
  lgUp: false,
  mdDown: false,
  mdUp: false,
  smDown: false,
  smUp: false,
  xlDown: false,
  xlUp: false,
  xsDown: false,
  xsUp: false
};
var _default = Hidden;
exports.default = _default;